body {
  background: white;
  margin: 0px;
}

.content {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-align: center;
}

a {
  text-decoration: none;
}

canvas.clicked {
  cursor: crosshair;
}
